export const fragmentShaderSource = `

precision highp float;

// our texture
// uniform sampler2D u_image;
uniform sampler2D u_image;
uniform sampler2D u_mask;
uniform sampler2D u_map;
uniform vec4 u_wires[60];
uniform int u_wires_size;
uniform int u_width;
uniform int u_height;
uniform int u_wireOffset;
uniform int u_isMap;
uniform float u_mapCursor;

// uniform float poisBright[20];

// the texCoords passed in from the vertex shader.
varying vec2 v_texCoord;

#define COTE 0.002
void main() {

//    vec4 image = texture2D(u_image, v_texCoord);
  vec4 mask = texture2D(u_mask, v_texCoord);
  vec4 image = texture2D(u_image, v_texCoord);
  vec4 map = texture2D(u_map, v_texCoord);

  float colorMap = 0.;
  float colorWire = 0.;

  if (u_wireOffset == 0){
    colorMap = map[2];
    colorWire = mask[2];
  } 
  if (u_wireOffset == 1){
    colorMap = map[1];
    colorWire = mask[1];
  } 
  if (u_wireOffset == 2){
    colorMap = map[0];
    colorWire = mask[0];
  } 

  if (colorWire != 1.) {

    if (abs(u_mapCursor - colorMap) <= 0.08){
      // float avg = (image[0] + image[1] + image[2]) / 3.;
      // image = mix(vec4(avg + 0.02,avg + 0.02,avg,1.), image, 0.5);

      vec4 oldImage = image;
      image = mix( vec4(1.,0.7,0.,1.),image, abs(u_mapCursor - colorMap) * 11.);
      image = mix(image, oldImage, 0.8);

    }

    for (int i=0; i <= 61; i++) {
      if (i >= u_wires_size) {
        break;
      }

      if (int( floor(colorWire * 256.00000) ) == i) {
          float alpha = u_wires[i][3];
          gl_FragColor = mix(image, u_wires[i], alpha);
          return;
      }
    }
  } 
  gl_FragColor = image;
}

`