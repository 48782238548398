import React, { useEffect } from 'react';
import './App.css';
import hive from "./hive"
import LeftMenu from "./hive/leftMenu"
import Header from "./hive/header"
import ToolTip from "./hive/tooltip"
import MobileFLoorMenu from "./hive/mobileFloorMenu"
import { useState } from "@hookstate/core"
import { R_HEADER_TOP_HEIGHT, R_HEADER_HEIGHT, R_MOBILE_HEADER_HEIGHT } from './hive/constant';
import GoBack from './hive/GoBack';
import Image from './hive/Image';
import Button from './hive/Button';
import BotMenu from './hive/BotMenu';
import MobileLogo from './hive/MobileLogo';
import Tour from './hive/Tour';
import InfoCard from './hive/InfoCard';
import Compas from './hive/Compas';
import SearchButton from './hive/SearchButton';
import Loading from './hive/Loading';
import { Fullscreen, ThreeDRotation } from '@material-ui/icons';
import Search from './hive/Search';
import Favorites from './hive/Favorites';
import RotateIcon from './hive/RotateIcon';

function FullScreenButton() {
  const { height, mainColor, totalWidth, isMobile } = useState(hive.state)

  const size = height.get() * (isMobile.get() ? 0.15 : 0.07);

  return <div
    onClick={() => {
      hive.toggleFullscreen()
    }}
    style={{
      position: "absolute",
      height: size,
      width: size,
      marginTop: height.get() - size * (isMobile.get() ? 2.5 : 1.1),
      marginLeft: totalWidth.get() - size * 1.2,
      zIndex: 3,
      color: `#${mainColor.get()}`,
      cursor: "pointer",
    }}>
    <Fullscreen style={{
      width: size + "px",
      height: size + "px",
    }}></Fullscreen>
  </div>
}

function LoadingBar() {
  const { loaded, totalWidth, height } = useState(hive.state)

  return <div style={{
    position: "absolute",
    width: totalWidth.get() * 0.3,
    height: height.get() * 0.035,
    marginLeft: totalWidth.get() * 0.5 - (totalWidth.get() * 0.3 * 0.5),
    marginTop: height.get() * 0.5 - (height.get() * 0.05 * 0.5),
    border: "1px solid #dadada",
    borderRadius: height.get() * 0.01,
    zIndex: 1,
    overflow: "hidden",
    pointerEvents: "none",
    transition: "opacity 0.5s",
    opacity: loaded.get() < 1 ? 1 : 0,
  }}>
    <div style={{
      width: "100%",
      height: "100%",
      marginLeft: `-${100 - loaded.get() * 100}%`,
      backgroundImage: "url(/gui/loaded.png)",
      backgroundAttachment: "fixed",
    }}>
    </div>
  </div>
}


function Detail() {
  const { pois, height } = useState(hive.state)
  const openHeight = height.get() * 0.2

  return <div>
    {pois.map(poi => <div key={poi.id.get()} style={{
      height: poi.open.get() ? openHeight : height.get() / pois.length - (openHeight / pois.length),
      // background: poi.open.get() ? "blue" : "white",
      borderBottom: "1px solid #dadada",
      cursor: "pointer",
      transition: "height 0.7s, background-color 0.5s",
      color: "#333333",
      overflow: "hidden",
      backgroundColor : poi.open.get() ? "#fff0da" : "initial",
      
    }}
      onMouseOver={() => {
        hive.onPoiFakeOver(poi.id.get())
      }}
    >
      <div style={{
        textAlign: "center",
        fontSize: height.get() * 0.03 + "px",
      }}>
        {poi.data.nested("name").get()}
        </div>
      <div style={{
        fontSize: height.get() * 0.015 + "px",
        paddingLeft: height.get() * 0.02,
        paddingRight: height.get() * 0.02,
      }}>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, vive modez quae ab illo inventore veritatis
      </div>
    </div>
    )}
  </div>
}

function App() {
  const { isMobile, loaded, height } = useState(hive.state)

  useEffect(() => {
    hive.vision.setContainer(document.querySelector("#visionContainer") as HTMLElement)
    hive.init()
    return () => { }
  }, [])

  return (
    <div className="App">
      {/* {!isMobile.get() && <Header></Header>} */}
      {/* {(isMobile.get() && !back.get()) && <MobileLogo></MobileLogo>} */}
      {/* {back.get() && <GoBack></GoBack>} */}
      {/* {image.get() && <Image></Image>} */}
      {/* {botMenu.get() && <BotMenu></BotMenu>} */}
      {/* {infoCard.get() && <InfoCard></InfoCard>} */}
      {/* {tour.get() && <Tour></Tour>} */}
      {/* {isMobile.get() && leftMenu.get().length > 0 && <>
        <Button
          onClick={() => { hive.toggleFloorMenu() }}
          topRatio={0.16}
          icon={"layer"}
          text={'Etages'}
        >
        </Button>

        {nbrLots.get() > 0 && <>
          <Button
            onClick={() => { hive.action("setScene", "search") }}
            topRatio={0.32}
            icon={"search"}
            text={'Filtrer'}
          >
          </Button>
          {favoriteList.length > 0 &&
            <Button
              onClick={() => { hive.action("setScene", "favorites") }}
              topRatio={0.48}
              icon={"favorite"}
              text={'Favoris'}
            >
            </Button>
          }
        </>}

      </>
      } */}

      {/* {<FullScreenButton></FullScreenButton>} */}
      <LoadingBar></LoadingBar>
      <RotateIcon></RotateIcon>

      {/* {compas.get() && <Compas></Compas>} */}
      {/* {(nbrLots.get() > 0 && searchButton.get() && !isMobile.get()) && <SearchButton></SearchButton>} */}
      {/* {search.get() && <Search></Search>} */}
      {/* {favorites.get() && <Favorites></Favorites>} */}

      {/* <Loading></Loading> */}
      <div style={{
        display: "flex",
        justifyContent: "center"
      }}>
        <div id="visionContainer" style={{
          width: "80vw",
          // height: isMobile.get() ? "100vh" : `calc(100vh - (100vh * ${R_HEADER_HEIGHT}))`,
          height: isMobile.get() ? "100vh" : `calc(100vh)`,
          userSelect: "none",
          visibility: loaded.get() < 1 ? "hidden" : "initial"
        }}>
        </div>
        <div style={{
          width: "20vw",
          height: height.get(),
        }}>
          <div style={{
            visibility: loaded.get() < 1 ? "hidden" : "initial"
          }}>
            <Detail></Detail>

          </div>
        </div>
      </div>
      {/* {isMobile.get() && leftMenu.get().length > 0 && <MobileFLoorMenu></MobileFLoorMenu>} */}
      {/* {!isMobile.get() && leftMenu.get().length > 0 && <LeftMenu></LeftMenu>} */}
      {/* <ToolTip></ToolTip> */}
    </div>
  );
}

export default App;
