export interface Project {
    mainColor: string,
    defaultScene: string;
    scenes: Scene[];
    typeColors: number[][];
    layouts: Layouts;
    price: boolean;
}

export interface Layouts {
    [key: string]: Layout;
}

export interface Layout {
    leftMenu?: LeftMenu[];
    image?: string
    [key: string]: any;
}

export interface LeftMenu {
    text: string;
    action: string;
    target: string;
    icon?: string;
}

export interface Scene {
    id: string;
    orbit?: string;
    layout: string;
    [key: string]: any;
}

const bot_menu_plan = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_plan"
    },
]

const bot_menu_plan_axo = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_plan_bot_plan_axo"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_axo_bot_plan_axo"
    },
]


const bot_menu_all = [
    {
        text: "Plan",
        action: "setLayout",
        target: "poi_plan"
    },
    {
        text: "Plan 3D",
        action: "setLayout",
        target: "poi_axo"
    },
    {
        text: "Visite 360",
        action: "setLayout",
        target: "poi_virtual"
    },
    {
        text: "Orbital",
        action: "setLayout",
        target: "orbit_T5A"
    }

]

const leftMenuBase = [
    // {
    //     text: "Skyview",
    //     action: "setScene",
    //     target: "Ciel",
    //     icon: "plane"
    // },
    {
        text: "Aerien",
        action: "setScene",
        target: "Roof",
        icon: "plane"
    },
    {
        text: "R+4",
        action: "setScene",
        target: "F5"
    },
    {
        text: "R+3",
        action: "setScene",
        target: "F4"
    },
    {
        text: "R+2",
        action: "setScene",
        target: "F3"
    },
    {
        text: "R+1",
        action: "setScene",
        target: "F2"
    },
    {
        text: "RDC",
        action: "setScene",
        target: "F1"
    },
]

export const project: Project = {
    mainColor: "f3d50d",
    defaultScene: "scene",
    price: true,
    typeColors: [
        [255, 186, 0, 255],
        [9, 128, 0, 255],
        [0, 21, 181, 255],
        [175, 0, 181, 255],
        [141, 0, 0, 255],
        [141, 65, 99, 255],
    ],

    // Same as poi
    scenes: [
        {
            id: "scene",
            orbit: "scene",
            layout: "base"
        },
    ],
    layouts: {
        sky: {
            tour: "%tour%",
            leftMenu: leftMenuBase,
        },

        orbit_T5A: {
            orbit: "T5A_Roof",
            back: "scene",
            infoCard: "%id%",
            compas: true,
            botMenu: bot_menu_all,
            leftMenu: [
                {
                    text: "Aerien",
                    action: "setOrbit",
                    target: "T5A_Roof",
                    icon: "plane"
                },
                {
                    text: "R+1",
                    action: "setOrbit",
                    target: "T5A_1"
                },
                {
                    text: "RDC",
                    action: "setOrbit",
                    target: "T5A_0"
                },
            ]
        },

        poi_virtual: {
            tour: "%tour%",
            back: "layout",
        },


        poi_plan_bot_plan_axo: {
            image: "%plan%",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_plan_axo,
        },
        poi_axo_bot_plan_axo: {
            image: "%axo%",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_plan_axo,
        },



        poi_plan: {
            image: "%plan%",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_plan,
        },
        poi_axo: {
            image: "%axo%",
            back: "scene",
            infoCard: "%id%",
            botMenu: bot_menu_plan,
        },



        base: {
            orbit: "%orbit%",
            // compas: true,
            // leftMenu: leftMenuBase,
            // searchButton: true
        },

        search: {
            back: "scene",
            backColor: "FFFFFF",
            search: true,
        },

        favorites: {
            back: "scene",
            favorites: true,
        }

    }
}
